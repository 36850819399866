import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { HEAD_CLASS } from '../share/components/heading/heading.component';

export function getParagraphCss(type: OIV_TYPE): string {
    switch (type) {
        case OIV_TYPE.INFO:
            return 'md:mobi-ui-font-size-300 xs:mobi-ui-font-size-400 mobi-ui-margin-top-xs';
        case OIV_TYPE.PARA:
            return 'md:mobi-ui-font-size-150 xs:mobi-ui-font-size-200';
        case OIV_TYPE.BOX_TITLE:
        case OIV_TYPE.LIST_ITEM_TITLE:
            return 'md:mobi-ui-font-size-150 xs:mobi-ui-font-size-200 mobi-ui-font-bold';
        default:
            return '';
    }
}
export function getHeadingCss(item: Content, hasNoneOrOneTopic?: boolean): HEAD_CLASS {
    const cssClass: HEAD_CLASS = 'mobi-ui-font-h2';
    if (item.type === OIV_TYPE.CHAPTER_TITLE && !hasNoneOrOneTopic) {
        return 'mobi-ui-font-h5';
    }
    return cssClass;
}
