import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ContentItem } from '../../model/atomic-model';

export type HEAD_CLASS = 'mobi-ui-font-h1' | 'mobi-ui-font-h2' | 'mobi-ui-font-h4' | 'mobi-ui-font-h5';

@Component({
    selector: 'oiv-viewer-heading',
    standalone: true,
    imports: [CommonModule, NgFor, NgIf],
    templateUrl: './heading.component.html',
    styleUrl: './heading.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
    heading = input<ContentItem>({
        eId: '',
        type: OIV_TYPE.CHAPTER_TITLE,
        content: '',
        attributeOrder: '',
    });
    cssClass = input('mobi-ui-font-h2');
}
