<div class="mobi-ui-ink-default mobi-ui-text-inked mobi-ui-font-tisa">
    @if (cssClass() === 'mobi-ui-font-h1') {
        <h1 [attr.id]="heading().eId">{{ heading().content }}</h1>
    } @else if (cssClass() === 'mobi-ui-font-h2') {
        <h2 [attr.id]="heading().eId">
            @if (heading().attributeOrder) {
                <span class="mobi-ui-margin-r-lg">{{ heading().attributeOrder }}</span>
            }
            {{ heading().content }}
        </h2>
    } @else if (cssClass() === 'mobi-ui-font-h4') {
        <h4 class="mobi-ui-padding-t-md" [attr.id]="heading().eId">{{ heading().content }}</h4>
    } @else if (cssClass() === 'mobi-ui-font-h5') {
        <h5 class="mobi-ui-padding-t-md" [attr.id]="heading().eId">{{ heading().content }}</h5>
    } @else {
        <p [attr.id]="heading().eId">{{ heading().content }}</p>
    }
</div>
